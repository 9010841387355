.wf100 {
	width: 100%;
	float: left;
}
.p100 {
	padding: 100px 0;
}
.p80 {
	padding: 80px 0;
}
/*********************************
Inner Header Start
*********************************/

.inner-header {
	background: url(../img/innerheader.jpg) no-repeat;
}
.inner-header h1 {
	color: #fff;
	margin: 0 0 20px;
	font-weight: 700;
}
.inner-header ul {
	margin: 0px;
	padding: 0px;
	list-style: none;
}
.inner-header ul li {
	display: inline-block;
	font-family: 'Poppins', sans-serif;
}
.inner-header ul li:after {
	content: " : : ";
	color: #fff;
	margin: 0 10px;
	font-size: 24px;
}
.inner-header ul li:last-child:after {
	display: none;
}
.inner-header ul li a {
	color: #fff;
	font-weight: 400;
	font-size: 24px;
}
/*********************************
Inner Header End
*********************************/
/*********************************
My Account Page Start
*********************************/

.myaccount-form h3 {
	margin: 0 0 30px;
	font-weight: 700;
}
.myaccount-form ul.row {
	margin: 0 -15px 0;
	padding: 0;
	list-style: none;
}
.login-box .input-group, .myaccount-form .input-group {
	margin-bottom: 30px;
}
.myaccount-form input.form-control {
	height: 53px;
	line-height: 51px;
	border-color: #cccccc;
}
.myaccount-form button.register {
	width: 50%;
	border: 0;
	border-radius: 5px;
	line-height: 53px;
	background: #66bb6a;
	color: #fff;
	font-size: 18px;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	font-weight: 500;
	cursor: pointer;
}
.login-box button.login-btn:hover, .myaccount-form button.register:hover {
	background: #1b5e20;
	color: #fff;
}
.myaccount-form a {
	color: #66bb6a;
}
.login-box a.fp:hover, .myaccount-form a:hover {
	color: #1b5e20;
}
.login-box {
	background: #66bb6a;
	width: 100%;
	float: left;
	border-radius: 5px;
	padding: 30px;
}
.login-box h3 {
	color: #fff;
	font-weight: 700;
	margin: 0 0 30px;
}
.login-box input.form-control {
	height: 53px;
	line-height: 51px;
	border-color: #fff;
	background: #fff;
}
.login-box label.form-check-label {
	color: #fff;
	font-size: 14px;
	text-transform: uppercase;
}
.login-box a.fp {
	color: #fff;
	font-size: 14px;
	text-transform: uppercase;
}
.login-box a.fp {
	color: #fff;
	font-size: 14px;
	text-transform: uppercase;
	margin: 0 0 0 50px;
	text-decoration: underline;
}
.login-box button.login-btn {
	background: #fff;
	border: 0;
	width: 100%;
	height: 53px;
	border-radius: 5px;
	line-height: 53px;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
	cursor: pointer;
}
/*********************************
My Account Page End
*********************************/
.fp-text {
	padding: 20px;
	position: relative;
}
.fp-text h6 a {
	color: #222222;
}
.fp-text strong {
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 16px;
	color: #689f38;
}
/*********************************
Footer Start
*********************************/
.footer {
	background: url(../img/footerbg.jpg) no-repeat;
	width: 100%;
	float: left;
	padding: 60px 0 0;
	background-size: cover;
}
.footer-widget {
	margin-bottom: 50px;
	width: 100%;
	float: left;
}
.footer-widget h4 {
	color: #9ccc65;
	margin-bottom: 15px;
	font-size: 24px;
	font-weight: 600;
}
.footer-widget a, .footer-widget p {
	color: #cccccc;
}
.footer-widget a:hover {
	color: #9ccc65;
}
.footer-widget a.lm {
	line-height: 40px;
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	display: inline-block;
	border-radius: 3px;
	text-transform: uppercase;
	padding: 0 20px;
}
ul.quick-links {
	margin: 0px;
	padding: 0px;
	list-style: none;
}
ul.quick-links li {
	line-height: 38px;
}
ul.quick-links li a {
	color: #ccc;
	font-size: 16px;
	font-weight: 600;
}
ul.quick-links li a:before {
	content: "\f00c";
	font-family: FontAwesome;
	color: #cccccc;
	font-size: 12px;
	margin-right: 10px;
}
ul.quick-links li a:hover {
	color: #9ccc65;
}
ul.lastest-products {
	padding: 0;
	margin: 0;
	list-style: none;
}
ul.lastest-products li {
	width: 100%;
	float: left;
	margin-bottom: 20px;
}
ul.lastest-products img {
	border-radius: 5px;
	float: left;
	margin-right: 10px;
}
ul.lastest-products strong {
	color: #ccc;
	font-weight: 500;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
}
ul.quick-links li a {
	font-family: 'Poppins', sans-serif;
}
span.pdate {
	color: #999999;
	font-size: 12px;
	display: block;
}
span.pdate i {
	font-style: normal;
	color: #9ccc65;
}
ul.lastest-products li:last-child {
	margin: 0px;
}
.f-product {
	border-radius: 5px;
	width: 100%;
	float: left;
	background: #fff;
	overflow: hidden;
	position: relative;
}
.f-product img {
	width: 100%;
	height: auto;
}
.fp-text {
	padding: 20px;
	position: relative;
}
.fp-text h6 a {
	color: #222222;
}
.fp-text strong {
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 16px;
	color: #689f38;
}
.footer-widget .owl-theme .owl-nav.disabled+.owl-dots {
	display: block;
	position: absolute;
	right: 5px;
	bottom: 70px;
}
.footer-widget .owl-theme .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	margin: 4px;
	border: 2px solid #bbbbbb;
	background: #ccc;
	display: block;
	-webkit-backface-visibility: visible;
	transition: opacity .2s ease;
	border-radius: 0;
}
.footer-copyr {
	border-top: 1px solid rgba(255,255,255,.3);
	padding: 20px 0;
}
.footer-copyr img {
	max-width: 120px;
	height: auto;
}
.footer-copyr p {
	text-align: right;
	font-size: 14px;
	color: #ccc;
	margin: 15px 0 0;
}
/*********************************
Footer End
*********************************/